<template>
    <div id="purchase-history">

        <b-modal ref="my-modal" scrollable title="Détail bon de livraison" ok-only ok-title="Annuler" size="xl"
            cancel-variant="outline-secondary">

            <b-table :bordered="true" :small="true" :hover="true" :no-border-collapse="true" :items="commandesSelected"
                :fields="fields" />

        </b-modal>


        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>

                <b-row>
                    <b-col cols="12" sm="10" class="text-left date-filter">
                        <b-row>
                            <b-col cols="12" sm="3">
                                <label for="datepicker-invalid">Date Début</label>
                                <b-form-datepicker size="sm" v-model="startDate" placeholder="Date Début"
                                    id="datepicker-debut" :max="new Date()" />
                            </b-col>
                            <b-col cols="12" sm="3">
                                <label for="datepicker-fin">Date Fin</label>
                                <b-form-datepicker size="sm" v-model="endDate" placeholder="Date Fin" id="datepicker-fin"
                                    :max="new Date()" />
                            </b-col>
                            <b-col cols="12" sm="6">
                                <label>Article</label>
                                <vue-autosuggest :suggestions="filteredOptions" :limit="10" item-text="description"
                                    item-value="code"
                                    :input-props="{ id: 'autosuggest__input', class: 'd-inline-block form-control form-control-sm', placeholder: 'Recherche par désignation d\'article' }"
                                    @input="onInputChange" :get-suggestion-value="getSuggestionValue">
                                    <template slot-scope="{suggestion}">
                                        <span class="my-suggestion-item">{{ suggestion.item.description }}</span>
                                    </template>
                                </vue-autosuggest>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="12" sm="2" class="text-right">
                        <b-button size="sm" variant="success" class="mt-2" @click="filterClicked">
                            <feather-icon icon="SearchIcon" class="mr-50 ml-50" />
                            Chercher
                        </b-button>
                    </b-col>
                </b-row>


                <ag-grid :showHeader="false" :agGrid="agGrid" pageTitle="Historique d'achat" />

            </b-card>
        </b-overlay>

    </div>
</template>
    
<script>
import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import BadgeCellRenderer from "@/views/components/ag-grid/BadgeCellRenderer.vue"
import { VueAutosuggest } from 'vue-autosuggest'

export default {
    components: {
        AgGrid,
        VueAutosuggest
    },
    data() {
        return {
            filteredOptions: [],
            limit: 10,
            selected: null,
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            reversedRows: [],
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Date Livraison",
                        field: "Posting_Date",
                        valueGetter: function (tr) {
                            return tr.data.Posting_Date.substring(0, 10);
                        },
                    },
                    {
                        headerName: "N° Document",
                        field: "Document_No",
                    },
                    {
                        headerName: "Qte",
                        field: "Quantity",
                        valueGetter: function (tr) {
                            return tr.data.Quantity * -1;
                        },
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.showDetails, icon: "read_more", tooltip: "voir les détails" },
                        width: 100
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        headerName: '',
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'buttonCellRenderer',
                        cellRendererParams: { onButtonClick: this.printDoc, icon: "picture_as_pdf", tooltip: "Imprimer" },
                        width: 100
                    },
                ],
                rows: []
            },
            fields: [{
                label: 'Code Article',
                key: 'No',
                sortable: true
            },
            {
                label: 'Désignation article',
                key: 'Description',
                sortable: true
            },
            {
                label: 'Prix',
                key: 'Unit_Price',
                sortable: true
            }, {
                label: 'Quantité',
                key: 'Quantity',
                sortable: true
            }
            ],
            commandesSelected: [],
            showLoading: false,
        }
    },
    computed: {
        datasuggest() {
            return this.$store.getters["app-items/geItemsMED"].concat(this.$store.getters["app-items/geItemsPARA"]);
        }
    },
    created() {
        this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        setTimeout(() => {
            this.agGrid.rows = [];
        }, 1000);
    },
    methods: {
        getSuggestionValue(suggestion) {
            this.selected = suggestion.item;
            return suggestion.item.description
        },
        onInputChange(text) {
            if (text === '' || text === undefined) {
                return
            }
            /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
            const filteredData = this.datasuggest.filter(item => item.description.toLowerCase().indexOf(text.toLowerCase()) > -1).slice(0, this.limit)
            this.filteredOptions = [{
                data: filteredData,
            }]
        },
        async printDoc(event) {
            this.showLoading = true;
            await this.$http.put("shipment_header", { Print: true }, { params: { No: "'" + event.Document_No + "'" } }).catch(() => this.showLoading = false);
            let response = await this.$http.get("file/document/" + event.Document_No.replace(/:/g, "") + ".pdf?type=bl", { responseType: "blob" }).catch(() => this.showLoading = false);
            this.showLoading = false;
            const fileURL = URL.createObjectURL(response.data);
            const a = document.createElement("a");
            a.href = fileURL;
            a.target = "_blank";
            a.rel = "noopener noreferrer";
            a.click();
            URL.revokeObjectURL(fileURL);
        },
        async filterClicked() {
            this.showLoading = true;
            let resp = await this.$http.get("_item/getItemNoByItemCode/" + this.selected.code).catch(() => this.showLoading = false);
            if (resp.data != "") {
                let response = await this.$http.get("sales-item-ledger-entry/findWhere?Item_No='" + resp.data + "'").catch(() => this.showLoading = false);
                this.agGrid.rows = response.data;
                this.showLoading = false;
            } else {
                this.$swal({
                    title: "Article Indisponible",
                    text: "Malheureusement, le grossiste sélectionné ne propose pas l'article " + this.selected.description,
                    icon: 'warning',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                });
                this.showLoading = false;
            }
        },
        async showDetails(event) {
            this.showLoading = !this.showLoading;
            let response = await this.$http.get("shipment_line/findWhere", {
                params: {
                    Document_No: "'" + event.Document_No + "'"
                }
            }).catch(() => this.showLoading = false);
            this.commandesSelected = response.data;
            this.showLoading = !this.showLoading;
            this.$refs['my-modal'].show();
        },
        generateDateFilters(No) {
            const filters = [
                {
                    "field": "Shipment_Date",
                    "value": "",
                    "operator": "gt"
                },
                {
                    "field": "Shipment_Date",
                    "value": "",
                    "operator": "lt"
                },
                {
                    "field": "No",
                    "value": "'" + No + "'",
                    "operator": "eq"
                }
            ];
            // Convert start date string to Date and adjust by -1 day
            const startDateObj = new Date(this.startDate);
            startDateObj.setDate(startDateObj.getDate() - 1);
            // Convert end date string to Date and adjust by +1 day
            const endDateObj = new Date(this.endDate);
            endDateObj.setDate(endDateObj.getDate() + 1);
            // Format adjusted dates as strings in yyyy-MM-dd format
            const formattedStartDate = startDateObj.toISOString().split('T')[0];
            const formattedEndDate = endDateObj.toISOString().split('T')[0];
            // Set adjusted date values in the filter objects
            filters[0].value = formattedStartDate;
            filters[1].value = formattedEndDate;
            return filters;
        }
    }
}
</script>
    
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>


<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>